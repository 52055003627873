const itens = [
  {
    destino: '/dashboard',
    titulo: 'Dashboard',
    icone: 'fa fa-fw fa-home',
    permissao: [0, 1],
  },
  // {
  //   destino: '/mensuracao',
  //   titulo: 'Mensuração',
  //   icone: 'fa fa-fw fa-chart-line',
  //   permissao: 0,
  // },
  {
    destino: '/relatorios',
    titulo: 'Relatórios',
    icone: 'fa fa-fw fa-paste',
    permissao: [1],
  },
  {
    destino: '/relatorio/resgate',
    titulo: 'Relatório Resgate',
    icone: 'fa fa-fw fa-paste',
    permissao: [1],
  },
  // {
  //   destino: '/taxa-sucesso',
  //   titulo: 'Taxa de Sucesso',
  //   icone: 'fa fa-fw fa-check',
  //   permissao: 1,
  // },
  {
    destino: '/pontuacoes',
    titulo: 'Pontuações',
    icone: 'fa fa-fw fa-user-plus',
    permissao: [0, 1],
    subItens: [
      {
        destino: '/pontuado',
        titulo: 'Pontuado',
        permissao: [0, 1],
      },
      {
        destino: '/nao-pontuavel',
        titulo: 'Não Pontuável',
        permissao: [0, 1],
      },
    ],
  },
  // {
  //   destino: '/antifraude',
  //   titulo: 'Antifraude',
  //   icone: 'fa fa-fw fa-file-contract',
  //   permissao: 0,
  // },
  // {
  //   destino: '/produtos-vendidos',
  //   titulo: 'Produtos Vendidos',
  //   icone: 'fa fa-fw fa-truck-loading',
  //   permissao: 0,
  // },
  // {
  //   destino: '/pesquisa-de-mercado',
  //   titulo: 'Pesquisa de Mercado',
  //   icone: 'fa fa-fw fa-file-signature',
  //   permissao: 0,
  // },
  {
    destino: '/resgates',
    titulo: 'Resgates',
    icone: 'fa fa-fw fa-gift',
    permissao: [0, 1, 2],
  },
  {
    destino: '/pontuacoes/historico',
    titulo: 'Histórico',
    icone: 'fa fa-fw fa-chart-area',
    permissao: [0, 1],
  },
  {
    destino: '/notificacoes-push',
    titulo: 'Notificações Push',
    icone: 'fa fa-fw fa-mobile',
    permissao: [0, 1],
  },
  {
    destino: '/pintores',
    titulo: 'Pintores',
    icone: 'fa fa-fw fa-users',
    permissao: [0, 1, 2],
  },
  {
    destino: '/empresas',
    titulo: 'Empresas',
    icone: 'fa fa-fw fa-users',
    permissao: [0, 1],
  },
  {
    destino: '/indicacoes',
    titulo: 'Indicações',
    icone: 'fa fa-fw fa-user-plus',
    permissao: [0, 1],
  },
  {
    destino: '/cursos',
    titulo: 'Cursos',
    icone: 'fa fa-fw fa-graduation-cap',
    permissao: [0, 1],
  },
  {
    destino: '/revendas',
    titulo: 'Revendas',
    icone: 'fa fa-fw fa-store',
    permissao: [0, 1],
  },
  {
    destino: '/lojas',
    titulo: 'Lojas',
    icone: 'fa fa-fw fa-store-alt',
    permissao: [0, 1],
  },
  {
    destino: '/fotos-videos',
    titulo: 'Fotos e Vídeos',
    icone: 'fa fa-fw fa-camera',
    permissao: [0, 1],
  },
  {
    destino: '/codigos-promocionais',
    titulo: 'Códigos Promocionais',
    icone: 'fa fa-fw fa-ticket-alt',
    permissao: [1],
  },
  {
    destino: '/vouchers',
    titulo: 'Vouchers',
    icone: 'fa fa-fw fa-handshake',
    permissao: [1],
  },
  // {
  //   destino: '/redes',
  //   titulo: 'Redes',
  //   icone: 'fa fa-fw fa-industry',
  //   permissao: 0,
  // },
  {
    destino: '/cartoes',
    titulo: 'Cartões',
    icone: 'fa fa-fw fa-credit-card',
    permissao: [1],
  },
  {
    destino: '/produtos',
    titulo: 'Produtos',
    icone: 'fa fa-fw fa-box',
    permissao: [0, 1],
  },
  {
    destino: '/premios',
    titulo: 'Premios',
    icone: 'fa fa-fw fa-gift',
    permissao: [0, 1],
  },
  {
    destino: '/banners',
    titulo: 'Banners',
    icone: 'fa fa-fw fa-bell',
    permissao: [0, 1],
  },
  {
    destino: '/verba-mensal',
    titulo: 'Verba Mensal',
    icone: 'fa fa-fw fa-file',
    permissao: [0, 1],
  },
  {
    destino: '/valor-ponto',
    titulo: 'Valor do Ponto',
    icone: 'fa fa-fw fa-coins',
    permissao: [0, 1],
  },
  // {
  //   destino: '/sw-recomenda',
  //   titulo: 'SW Recomenda',
  //   icone: 'fa fa-box-open',
  //   permissao: 0,
  // },
  {
    destino: '/cadastrar',
    titulo: 'Cadastrar',
    icone: 'fa fa-fw fa-edit',
    permissao: [1],
    subItens: [
      {
        destino: '/administrador',
        titulo: 'Administrador',
        permissao: [0],
      },
      // {
      //   destino: '/rede',
      //   titulo: 'Rede',
      //   permissao: 0,
      // },
      {
        destino: '/revenda',
        titulo: 'Revenda',
        permissao: [0, 1],
      },
      {
        destino: '/premio',
        titulo: 'Prêmio',
        permissao: [0, 1],
      },
      {
        destino: '/produto',
        titulo: 'Produto',
        permissao: [0, 1],
      },
      // {
      //   destino: '/pesquisa-mercado',
      //   titulo: 'Pesquisa de Mercado',
      //   permissao: 0,
      // },
      {
        destino: '/codigo-promocional',
        titulo: 'Código Promocional',
        permissao: [0, 1],
      },
      {
        destino: '/voucher',
        titulo: 'Voucher',
        permissao: [0, 1],
      },
      {
        destino: '/banner',
        titulo: 'Banner',
        permissao: [0, 1],
      },
    ],
  },
  {
    destino: '/processar',
    titulo: 'Processar',
    icone: 'fa fa-fw fa-upload',
    permissao: [1],
    subItens: [
      {
        destino: '/produtos',
        titulo: 'Produtos',
        permissao: [0, 1],
      },
      // {
      //   destino: '/vendas',
      //   titulo: 'Vendas',
      //   permissao: 0,
      // },
      // {
      //   destino: '/historico',
      //   titulo: 'Histórico',
      //   permissao: 0,
      // },
      // {
      //   destino: '/nova-venda',
      //   titulo: 'Nova Venda',
      //   permissao: 0,
      // },
      {
        destino: '/pontuacoes',
        titulo: 'Pontuações Pendentes',
        permissao: [0, 1],
      },
      // {
      //   destino: '/saldo-cartao',
      //   titulo: 'Saldo Cartão',
      //   permissao: 0,
      // },
      // {
      //   destino: '/relatorio',
      //   titulo: 'Relatório',
      //   permissao: 0,
      // },
      {
        destino: '/pontos',
        titulo: 'Pontos',
        permissao: [0, 1],
      },
      // {
      //   destino: '/correcao-pontuacao',
      //   titulo: 'Correção de Pontuação',
      //   permissao: 0,
      // },
      {
        destino: '/foto-video',
        titulo: 'Foto/Vídeo',
        permissao: [0, 1],
      },
    ],
  },
];

export default itens;
